<script lang="ts" setup></script>

<template>
  <div>
    <div class="text-center text-lg md:text-2xl">ขออภัย! เกิดข้อผิดพลาดในเกตเวย์</div>
    <div class="text-center text-sm md:text-lg text-grey-4">เซิร์ฟเวอร์ที่เป็นเกตเวย์ไม่สามารถรับการตอบกลับจากเซิร์ฟเวอร์หลักได้ในขณะนี้</div>
  </div>
</template>

<style scoped></style>
